<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Добавить
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Контроллер
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle class="data__text">Данные контроллера</FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              rules="required"
              label="Название"
              v-model="name"
              validate-name="название"
            ></ValidationInputField>

            <ValidationAutocompleteField
              :search-function="citySearch"
              :disabled="
                !!companyId || !!kindergartenId || !!objectId || !!organization
              "
              rules="required"
              label="Город"
              v-if="[70].includes(currentRole)"
              v-model="city"
              validate-name="город"
            />
            <ValidationSelectField
              :items="controllerType"
              rules="required"
              label="Тип контроллера"
              v-model="type"
              validate-name="тип контроллера"
            />
            <ValidationAutocompleteField
              :search-function="companySearch"
              rules=""
              label="Компания"
              v-if="[70, 60].includes(currentRole) && !!objectId"
              v-model="organization"
              validate-name="компания"
              :disabled="!!companyId || !!kindergartenId || !!objectId"
            />
            <ValidationAutocompleteField
              :search-function="objectSearch"
              rules="required"
              label="Объект"
              v-model="object"
              v-if="!!objectId"
              :disabled="!!objectId || !!kindergartenId"
              validate-name="объект"
            />
            <ValidationAutocompleteField
              :search-function="companySearchKG"
              rules=""
              label="Компания"
              v-if="[70, 60].includes(currentRole) && kindergartenId"
              v-model="organization"
              validate-name="компания"
              :disabled="!!companyId || !!kindergartenId || !!objectId"
            />
            <ValidationAutocompleteField
              :search-function="companySearchKG"
              rules=""
              label="Объект"
              v-if="[70, 60].includes(currentRole) && kindergartenId"
              v-model="organization"
              validate-name="объект"
              :disabled="!!companyId || !!kindergartenId || !!objectId"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(createObject)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import { getCitiesRequest } from "@/helpers/api/cities";
import {
  getOrganizationsByIdRequest,
  getOrganizationsClearByIdRequest,
  getOrganizationsRequest
} from "@/helpers/api/organizations";
import { getObjectByIdRequest, getObjectsRequest } from "@/helpers/api/objects";
import { getKindergartenByIdRequest } from "@/helpers/api/kindergartens";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
export default {
  name: "ControllerCreatePage",
  components: {
    ValidationSelectField,
    ValidationAutocompleteField,
    ValidationInputField,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    MainLayout
  },
  props: {
    dealerId: {
      type: [String, Number]
    },
    companyId: {
      type: [String, Number]
    },
    objectId: {
      type: [String, Number]
    },
    kindergartenId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      loading: false,
      controllerType: [
        {
          value: "door",
          text: "Дверь"
        },
        {
          value: "wicket",
          text: "Калитка"
        },
        {
          value: "wing-gate",
          text: "Ворота распашные"
        },
        {
          value: "sliding-gate",
          text: "Ворота откатные"
        },
        {
          value: "lift-gate",
          text: "Шлагбаум"
        },
        {
          value: "porch-door",
          text: "Дверь подъездная"
        },
        {
          value: "inner-door",
          text: "Дверь внутренняя"
        },
        {
          value: "apartment-room",
          text: "Дверь квартирная"
        },
        {
          value: "lift",
          text: "Лифт"
        },
        {
          value: "mailbox",
          text: "Почтовый ящик"
        },
        {
          value: "garage-gate",
          text: "Гаражные ворота"
        },
        {
          value: "turnstile",
          text: "Турникет"
        }
      ],
      citySearch: async value => {
        return (
          await getCitiesRequest({ search: value, offset: null })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      companySearch: async value => {
        return (
          await getOrganizationsRequest({
            query: {
              limit: 10000,
              search: value,
              types: ["default"],
              city: this.city,
              diller: this.diller || this.dealerId || ""
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      companySearchKG: async value => {
        return (
          await getOrganizationsRequest({
            query: {
              limit: 10000,
              search: value,
              types: ["kindergarten"],
              city: this.city,
              diller: this.diller || this.dealerId || ""
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      objectSearch: async value => {
        return (
          await getObjectsRequest({
            query: {
              limit: 10000,
              search: value,
              city: this.city
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      }
    };
  },
  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    object: {
      get() {
        return this.$store.getters.getControllerCreateForm.object;
      },
      set(newValue) {
        this.$store.commit("setControllerCreateForm", {
          fieldName: "object",
          value: newValue
        });
      }
    }, //id города
    organization: {
      get() {
        return this.$store.getters.getControllerCreateForm.organization;
      },
      set(newValue) {
        this.$store.commit("setControllerCreateForm", {
          fieldName: "organization",
          value: newValue
        });
      }
    }, //id города
    city: {
      get() {
        return this.$store.getters.getControllerCreateForm.city;
      },
      set(newValue) {
        this.$store.commit("setControllerCreateForm", {
          fieldName: "city",
          value: newValue
        });
      }
    }, //id города
    diller: {
      get() {
        return this.$store.getters.getControllerCreateForm.diller;
      },
      set(newValue) {
        this.$store.commit("setControllerCreateForm", {
          fieldName: "diller",
          value: newValue
        });
      }
    }, //diller
    name: {
      get() {
        return this.$store.getters.getControllerCreateForm.name;
      },
      set(newValue) {
        this.$store.commit("setControllerCreateForm", {
          fieldName: "name",
          value: newValue
        });
      }
    }, //name,
    type: {
      get() {
        return this.$store.getters.getControllerCreateForm.type;
      },
      set(newValue) {
        this.$store.commit("setControllerCreateForm", {
          fieldName: "type",
          value: newValue
        });
      }
    } //name
  },
  created() {
    const curOrg = this.$store.getters.getCurrentOrganization;
    if (this.currentRole === 60) {
      this.diller = curOrg;
      getOrganizationsClearByIdRequest({ id: curOrg }).then(res => {
        this.city = res.data.city;
      });
    }
    if (this.currentRole === 30) {
      this.organization = curOrg;
      getOrganizationsClearByIdRequest({ id: curOrg }).then(res => {
        this.diller = res.data.diller;
        this.city = res.data.city;
      });
    }

    if (this.companyId) {
      this.organization = Number(this.companyId);
      getOrganizationsByIdRequest({ id: this.organization }).then(response => {
        this.city = response.data.city_details.id;
      });
    }
    if (this.objectId) {
      this.object = Number(this.objectId);
      if (!this.companyId) {
        getObjectByIdRequest({ id: this.object }).then(response => {
          this.organization = response.data.organization;
          this.city = response.data.city_details.id;
        });
      }
    }
    if (this.kindergartenId) {
      this.organization = Number(this.kindergartenId);
      getKindergartenByIdRequest({
        id: this.kindergartenId
      }).then(response => {
        this.object = response.data.object.id;
        this.city =
          response?.data?.city?.id || response?.data?.city_details?.id;
      });
    }
  },
  beforeDestroy() {
    this.$store.commit("clearCreateControllerForm");
  },
  methods: {
    createObject() {
      this.loading = true;
      this.$store
        .dispatch("createController")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.controller)
          );
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
